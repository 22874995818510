import React, { useState, useEffect, useMemo } from "react";
import { Grid, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";

import { Box, Input, Button, Table, Select } from "components";
import {
  EditIcon,
  DeleteIcon,
  AttentionIcon,
  SendCompleteIcon,
  ConfirmFailIcon,
  CloseIcon,
} from "icons";
import { EMAIL_REGEX, PASSWORD_REGEX } from "constant";
import { httpStatus } from "configs/httpStatus";
import { IOption } from "components/select/Select";

import { ILinkHospital, IDoctor } from "models/doctor/doctorsList";
import {
  GetDoctorsListActionCreators,
  DeleteDoctorActionCreators,
  CreateDoctorActionCreators,
  EditDoctorActionCreators,
  GetHospitalsActionCreators,
} from "redux/rootActions";
import {
  selectIsLoadingDoctors,
  doctorsListDataResponse,
  selectDoctorsErrorMessage,
} from "redux/doctor/doctorsList/doctorsListState";
import {
  selectIsLoadingDeleteDoctor,
  deleteDoctorErrorMessage,
  deleteDoctorDataResponse,
} from "redux/doctor/deleteDoctor/deleteDoctorStates";
import {
  selectIsCreatingDoctor,
  createDoctorErrorMessage,
  createDoctorDataResponse,
} from "redux/doctor/createDoctor/createDoctorStates";
import {
  selectIsEditingDoctor,
  editDoctorErrorMessage,
  editDoctorDataResponse,
} from "redux/doctor/editDoctor/editDoctorStates";
import {
  selectIsLoadingHospital,
  selectHospitalErrorMessage,
  hospitalListDataResponse,
} from "redux/hospitals/hospitalsList/hospitalsListStates";

import {
  GroupInput,
  Title,
  WrapperFirstName,
  WrapperBtnFooter,
  WrapperContentModal,
  ModalStyled,
  WrapperPhysicianManagement,
  WrapperCreatePhysician,
  GroupInputWrapper,
  TableStyled,
  HospitalColItem,
} from "./PhysicianManagement.style";
import {
  WrapperSchedule,
  Alert,
  AlertMobile,
  Tooltip4AlertMobile,
} from "../schedules/Schedule.style";

const { useBreakpoint } = Grid;

const schema = yup.object().shape({
  last_name: yup.string().trim().required("姓を入力してください"),
  first_name: yup.string().trim().required("名を入力してください"),
  kana_last_name: yup
    .string()
    .trim()
    .required("姓（ふりがな）を入力してください"),
  kana_first_name: yup
    .string()
    .trim()
    .required("名（ふりがな）を入力してください"),
  email: yup
    .string()
    .trim()
    .required("メールアドレスを入力してください")
    .matches(EMAIL_REGEX, "メールアドレスの正しい形式で入力してください"),
  password: yup.string().trim().required("パスワードを入力してください"),
  //   .matches(PASSWORD_REGEX, "password-requirements"),
  // password_confirmation: yup
  //   .string()
  //   .required("confirm-password-required")
  //   .oneOf([yup.ref("password")], "password-not-match"),
});

const PhysicianManagement = () => {
  const dispatch = useDispatch();

  const isLoadingDoctor = useSelector(selectIsLoadingDoctors);
  const isCreatingDoctor = useSelector(selectIsCreatingDoctor);
  const isEditingDoctor = useSelector(selectIsEditingDoctor);
  const loadingHospital = useSelector(selectIsLoadingHospital);
  const deletingDoctor = useSelector(selectIsLoadingDeleteDoctor);

  const DeleteDoctorError = useSelector(deleteDoctorErrorMessage);
  const CreateDoctorError = useSelector(createDoctorErrorMessage);
  const editDoctorError = useSelector(editDoctorErrorMessage);
  const getDoctorsError = useSelector(selectDoctorsErrorMessage);
  const getHospitalsError = useSelector(selectHospitalErrorMessage);

  const doctorList = useSelector(doctorsListDataResponse);
  const deleteDoctorResponse = useSelector(deleteDoctorDataResponse);
  const createDoctorResponse = useSelector(createDoctorDataResponse);
  const editDoctorResponse = useSelector(editDoctorDataResponse);
  const hospitalListData = useSelector(hospitalListDataResponse);

  const { handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const [showEdit, setShowEdit] = useState<any>({});
  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
  const [isVisibleTooltip, setIsVisibleTooltip] = useState<boolean>(false);
  const [selectedDoctor, setSelectedDoctor] = useState<any>();
  const [editDoctor, setEditDoctor] = useState<{
    id: any;
    email: string;
    link_hospitals: any[];
  }>({
    id: undefined,
    email: "",
    link_hospitals: [],
  });
  const [selectedHospital, setSelectedHospital] = useState<any>(undefined);
  const [selectedDoctorHospital, setSelectedDoctorHospital] =
    useState<any>(undefined);
  const [selectedHospitals, setSelectedHospitals] = useState<any[]>([]);
  const [selectHospitalError, setSelectHospitalError] = useState<string>("");
  const [editDoctorHospitalError, setEditDoctorHospitalError] =
    useState<string>("");
  const [editDoctorEmailError, setEditDoctorEmailError] = useState<string>("");

  const screens = useBreakpoint();

  const createOptions = useMemo<IOption[]>(() => {
    const tempOptions: IOption[] = [];
    if (hospitalListData?.result && hospitalListData?.result.length) {
      hospitalListData.result.map((hospital: any) =>
        tempOptions.push({
          id: hospital.hospital_id,
          name: `${hospital.name}${
            hospital.super_city_name && ` / ${hospital.super_city_name}`
          }`,
          value: hospital.hospital_id,
        })
      );
    }
    if (!selectedHospitals.length) {
      return tempOptions;
    }
    if (selectedHospitals.length) {
      const tempOpts = [...tempOptions];
      const newTempOpts: IOption[] = [];

      tempOpts.map((opt: IOption) => {
        if (selectedHospitals.includes(opt.value)) {
          newTempOpts.push({
            ...opt,
            disabled: true,
          });
        } else {
          newTempOpts.push(opt);
        }
      });

      return newTempOpts;
    }
    return [];
  }, [hospitalListData, selectedHospitals]);

  const createIndividualOptions = useMemo<IOption[]>(() => {
    const tempOptions: IOption[] = [];
    const tempHospital: any[] = [];
    if (hospitalListData?.result && hospitalListData?.result.length) {
      hospitalListData.result.map((hospital: any) =>
        tempOptions.push({
          id: hospital.hospital_id,
          name: `${hospital.name}${
            hospital.super_city_name && ` / ${hospital.super_city_name}`
          }`,
          value: hospital.hospital_id,
        })
      );
    }
    if (!editDoctor.link_hospitals.length) {
      return tempOptions;
    }
    if (editDoctor.link_hospitals.length) {
      const tempOpts = [...tempOptions];
      const newTempOpts: IOption[] = [];
      editDoctor.link_hospitals.map((hospital: any) =>
        tempHospital.push(hospital.hospital_id)
      );

      tempOpts.map((opt: IOption) => {
        if (tempHospital.length && tempHospital.includes(opt.value)) {
          newTempOpts.push({
            ...opt,
            disabled: true,
          });
        } else {
          newTempOpts.push(opt);
        }
      });

      return newTempOpts;
    }
    return [];
  }, [hospitalListData, editDoctor]);

  const handleDeleteDoctor = () => {
    setIsConfirmModal(false);
    if (selectedDoctor) {
      dispatch(
        DeleteDoctorActionCreators.deleteDoctorAction({
          doctor_id: selectedDoctor,
        })
      );
    }
  };

  const handleCreateDoctor = (data: { [x: string]: any }) => {
    const {
      email,
      password,
      first_name,
      last_name,
      kana_first_name,
      kana_last_name,
    } = data;

    if (!selectedHospitals.length) {
      setSelectHospitalError("所属病院を選択してください");
      return false;
    }

    dispatch(
      CreateDoctorActionCreators.createDoctorAction({
        doctor: {
          email,
          password: password ? password : "",
          first_name,
          last_name,
          kana_first_name,
          kana_last_name,
          link_hospitals: selectedHospitals,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(GetDoctorsListActionCreators.getDoctorsListAction({}));
    dispatch(GetHospitalsActionCreators.getHospitalListAction());
  }, []);

  useEffect(() => {
    if (
      createDoctorResponse &&
      createDoctorResponse.status === httpStatus.StatusCreated
    ) {
      notification.open({
        message: moment(new Date()).format("YYYY/MM/DD HH:mm"),
        description: createDoctorResponse.message,
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      reset();
      setValue("first_name", "");
      setValue("last_name", "");
      setValue("kana_first_name", "");
      setValue("kana_last_name", "");
      setValue("email", "");
      setValue("password", "");
      setSelectedHospitals([]);
      setSelectedHospital(undefined);
      setSelectHospitalError("");
      dispatch(CreateDoctorActionCreators.handleResetAction());
      dispatch(GetDoctorsListActionCreators.getDoctorsListAction({}));
    }
  }, [createDoctorResponse]);

  useEffect(() => {
    if (deleteDoctorResponse && deleteDoctorResponse?.message) {
      notification.success({
        placement: "bottomRight",
        message: deleteDoctorResponse.message,
        icon: <SendCompleteIcon />,
      });
      setSelectedDoctor(undefined);
      dispatch(DeleteDoctorActionCreators.handleResetAction());
      dispatch(GetDoctorsListActionCreators.getDoctorsListAction({}));
    }
  }, [deleteDoctorResponse]);

  useEffect(() => {
    if (editDoctorResponse && editDoctorResponse?.message && !isEditingDoctor) {
      notification.success({
        placement: "bottomRight",
        message: "医師情報を更新しました。",
        icon: <SendCompleteIcon />,
      });
      setSelectedDoctor(undefined);
      dispatch(EditDoctorActionCreators.handleResetAction());
      dispatch(GetDoctorsListActionCreators.getDoctorsListAction({}));
    }
  }, [editDoctorResponse]);

  useEffect(() => {
    if (DeleteDoctorError && !deletingDoctor) {
      notification.error({
        placement: "bottomRight",
        message: DeleteDoctorError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      setSelectedDoctor(undefined);
      dispatch(DeleteDoctorActionCreators.handleResetAction());
    }
  }, [DeleteDoctorError, deletingDoctor]);

  useEffect(() => {
    if (getDoctorsError) {
      dispatch(GetDoctorsListActionCreators.handleResetAction());
    }
  }, [getDoctorsError]);

  useEffect(() => {
    if (CreateDoctorError) {
      notification.error({
        placement: "bottomRight",
        message: CreateDoctorError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      // setValue("first_name", "");
      // setValue("last_name", "");
      // setValue("kana_first_name", "");
      // setValue("kana_last_name", "");
      // setValue("email", "");
      // setValue("password", "");
      // setSelectedHospitals([]);
      // setSelectedHospital(undefined);
      dispatch(CreateDoctorActionCreators.handleResetAction());
    }
  }, [CreateDoctorError]);

  useEffect(() => {
    if (editDoctorError) {
      notification.error({
        placement: "bottomRight",
        message: editDoctorError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      setEditDoctor({
        id: undefined,
        email: "",
        link_hospitals: [],
      });
      dispatch(EditDoctorActionCreators.handleResetAction());
    }
  }, [editDoctorError]);

  const showWarning = (temperature?: number) => {
    if (temperature) {
      return "";
    }
    const Message = () => {
      //   return (
      //     <Trans i18nKey="body-temperature.warning-first">
      //       {{ percent: temperature }}
      //     </Trans>
      //   );

      return <div>20:30に大島大輔さんからの予約が入りました。</div>;
    };
    return (
      <React.Fragment>
        {screens.md ? (
          //   <Alert background={temperature >= 5 ? "#FB2121" : "#EBC300"}>
          <Alert background={"#2F8CAE"}>
            <AttentionIcon style={{ marginRight: 8 }} />
            <Message />
          </Alert>
        ) : (
          <React.Fragment>
            {isVisibleTooltip && (
              <Tooltip4AlertMobile>
                <Message />
              </Tooltip4AlertMobile>
            )}
            <AlertMobile
              background={"#2F8CAE"}
              onClick={() => console.log("clicked")}
            >
              <AttentionIcon style={{ marginRight: 8 }} />
            </AlertMobile>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const returnName = (id: any) => {
    if (createOptions && createOptions.length) {
      const item = createOptions.find((item: IOption) => item.id === id);
      if (item) {
        return item.name;
      }
    } else {
      return "";
    }
  };

  const returnHospitalList = (list: any[]) => {
    if (!list || !list.length) {
      return "";
    } else if (list.length === 1) {
      return list[0]?.name;
    } else {
      let hospitals = "";

      list.map((item: any, index: number) => {
        if (index === 0) {
          hospitals += `${item.name}${
            item.super_city_name && ` / ${item.super_city_name}`
          }`;
        } else {
          hospitals += `\n${item.name}${
            item.super_city_name && ` / ${item.super_city_name}`
          }`;
        }
      });

      return hospitals;
    }
  };

  const returnEditHospitals = (hospitals: any[]) => {
    if (!hospitals.length) {
      return [];
    } else {
      const ids: any[] = [];
      hospitals.map((item: any) => ids.push(item.hospital_id));

      return ids;
    }
  };

  const columns = [
    {
      title: "名前",
      dataIndex: "doctor_name",
      key: "doctor_name",
      width: 210,
      fixed: window.innerWidth < 576 ? "" : "left",
      render: (text: string, record: any) => {
        return showEdit[record.id] ? (
          <WrapperFirstName>
            <Button
              onClick={() => {
                if (
                  editDoctor.email !== record.email ||
                  (editDoctor.link_hospitals.length &&
                    ((record.link_hospitals &&
                      editDoctor.link_hospitals !== record.link_hospitals) ||
                      !record.link_hospitals)) ||
                  !editDoctor.link_hospitals.length
                ) {
                  if (!editDoctor.email) {
                    setEditDoctorEmailError("メールアドレスを入力してください");
                    return false;
                  }
                  if (
                    editDoctor.email &&
                    !editDoctor.email.match(EMAIL_REGEX)
                  ) {
                    setEditDoctorEmailError(
                      "メールアドレスの正しい形式で入力してください"
                    );
                    return false;
                  }
                  if (!editDoctor.link_hospitals.length) {
                    setEditDoctorHospitalError("所属病院を選択してください");
                    return false;
                  }
                  setShowEdit({
                    [record.id]: false,
                  });
                  setEditDoctorEmailError("");
                  dispatch(
                    EditDoctorActionCreators.editDoctorAction({
                      id: editDoctor.id,
                      doctor: {
                        email: editDoctor.email,
                        link_hospitals: returnEditHospitals(
                          editDoctor.link_hospitals
                        ),
                      },
                    })
                  );
                } else {
                  setEditDoctor({
                    id: undefined,
                    email: "",
                    link_hospitals: [],
                  });
                  setEditDoctorHospitalError("");
                  setEditDoctorEmailError("");
                  setShowEdit({
                    [record.id]: false,
                  });
                }
              }}
              background="#2F8CAE"
              color="#fff"
              name="保存"
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
              width="44px"
            />
            <Input value={text} fs={16} height={28} disabled={true} />
          </WrapperFirstName>
        ) : (
          <div
            style={{
              display: "flex",
              gap: 24,
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <EditIcon
              fill="#3B3B3B"
              onClick={() => {
                setShowEdit({
                  [record.id]: true,
                });
                setEditDoctor({
                  id: record.id,
                  email: record.email,
                  link_hospitals: record.link_hospitals
                    ? record.link_hospitals
                    : [],
                });
              }}
            />
            {text}
          </div>
        );
      },
    },
    {
      title: "メール",
      dataIndex: "email",
      key: "email",
      width: 254,
      render: (text: string, record: any) => {
        return showEdit[record.id] ? (
          <>
            <Input
              value={editDoctor.email ? editDoctor.email : ""}
              height={28}
              fs={16}
              onChange={(e: any) => {
                if (!e.target.value) {
                  setEditDoctorEmailError("メールアドレスを入力してください");
                } else {
                  setEditDoctorEmailError("");
                }
                setEditDoctor({
                  ...editDoctor,
                  email: e.target.value,
                });
              }}
            />
            {editDoctorEmailError && (
              <div
                style={{
                  fontWeight: 500,
                  lineHeight: "16px",
                  fontSize: 12,
                  color: "#FB4E4E",
                  marginTop: 8,
                  marginLeft: 2,
                }}
              >
                {editDoctorEmailError}
              </div>
            )}
          </>
        ) : (
          <div>{text}</div>
        );
      },
    },
    {
      title: "ふりがな",
      dataIndex: "kana_doctor_name",
      key: "kana_doctor_name",
      width: 204,
      render: (text: string, record: any) => {
        return showEdit[record.id] ? (
          <Input value={text} fs={16} height={28} disabled={true} />
        ) : (
          <div>{text}</div>
        );
      },
    },
    // {
    //   title: "パスワード",
    //   dataIndex: "password",
    //   key: "password",
    //   width: 224,
    //   render: (text: string, record: any) => {
    //     return showEdit[record.id] ? (
    //       <Input value={text} fs={16} height={28} disabled={true} />
    //     ) : (
    //       <div>{text}</div>
    //     );
    //   },
    // },
    {
      title: "所属病院",
      dataIndex: "link_hospitals",
      key: "link_hospitals",
      width: 252,
      render: (text: string, record: any) => {
        return showEdit[record.id] ? (
          <HospitalColItem>
            <div className="select-block">
              <Select
                value={selectedDoctorHospital}
                width="156px"
                options={createIndividualOptions}
                placeholder="選択してください"
                height="31px"
                background="#ffffff"
                onChange={(e: any) => {
                  setSelectedDoctorHospital(e);
                }}
                // error={editDoctorHospitalError ? editDoctorHospitalError : ""}
              />
              <Button
                onClick={() => {
                  if (
                    editDoctor.link_hospitals.length &&
                    (selectedDoctorHospital || selectedDoctorHospital === 0) &&
                    !editDoctor.link_hospitals.includes(selectedDoctorHospital)
                  ) {
                    const item: any = createIndividualOptions.find(
                      (item: IOption) => item.id === selectedDoctorHospital
                    );
                    setEditDoctor({
                      ...editDoctor,
                      link_hospitals: [
                        ...editDoctor.link_hospitals,
                        {
                          hospital_id: selectedDoctorHospital,
                          name: item ? item.name : "",
                        },
                      ],
                    });
                  }
                  if (
                    !editDoctor.link_hospitals.length &&
                    (selectedDoctorHospital || selectedDoctorHospital === 0)
                  ) {
                    const item: any = createIndividualOptions.find(
                      (item: IOption) => item.id === selectedDoctorHospital
                    );
                    setEditDoctor({
                      ...editDoctor,
                      link_hospitals: [
                        {
                          hospital_id: selectedDoctorHospital,
                          name: item ? item.name : "",
                        },
                      ],
                    });
                    setEditDoctorHospitalError("");
                  }
                  setSelectedDoctorHospital(undefined);
                }}
                background="#2AC769"
                color="#fff"
                name="追加"
                border="none"
                bdr="4px"
                fontSize={14}
                lineHeight="20px"
                fontWeight={400}
                disabled={isCreatingDoctor}
                padding="6px 8px 5px"
              />
            </div>
            {editDoctorHospitalError && (
              <div
                style={{
                  fontWeight: 500,
                  lineHeight: "16px",
                  fontSize: 12,
                  color: "#FB4E4E",
                  marginTop: 8,
                  marginLeft: 2,
                }}
              >
                {editDoctorHospitalError}
              </div>
            )}
            {editDoctor.link_hospitals &&
              editDoctor.link_hospitals.length > 0 && (
                <div className="flex-wrapper">
                  {editDoctor.link_hospitals.map((hospital: any) => (
                    <div key={hospital.hospital_id} className="badge">
                      <span>
                        {hospital.name}
                        {hospital.super_city_name &&
                          ` / ${hospital.super_city_name}`}
                      </span>
                      <CloseIcon
                        width={12}
                        height={12}
                        fill="#3B3B3B"
                        onClick={() => {
                          const tempSelectedHospitals = [
                            ...editDoctor.link_hospitals,
                          ];
                          setEditDoctor({
                            ...editDoctor,
                            link_hospitals: tempSelectedHospitals.filter(
                              (item: any) =>
                                item.hospital_id !== hospital.hospital_id
                            ),
                          });
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
          </HospitalColItem>
        ) : (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <div style={{ flex: 1, minHeight: 24, whiteSpace: "break-spaces" }}>
              {record.link_hospitals &&
                returnHospitalList(record.link_hospitals)}
            </div>
            <DeleteIcon
              fill="#FB4E4E"
              onClick={() => {
                setSelectedDoctor(record?.id);
                setIsConfirmModal(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <WrapperPhysicianManagement>
      {/* {showWarning()} */}
      <Box
        title="登録医師管理"
        padding="12px 16px 24px"
        style={{ marginBottom: 24 }}
      >
        <form noValidate onSubmit={handleSubmit(handleCreateDoctor)}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 13,
            }}
          >
            <div style={{ display: "flex", alignItems: "flex-end", gap: 8 }}>
              <Title style={{ marginBottom: 0 }}>医師登録</Title>
              <div
                style={{
                  fontWeight: 500,
                  lineHeight: "16px",
                  fontSize: 10,
                  color: "#FB4E4E",
                }}
              >
                ※項目はすべて入力･選択してください
              </div>
            </div>
            <Button
              type="submit"
              onClick={() => {
                if (!selectedHospitals.length) {
                  setSelectHospitalError("所属病院を選択してください");
                  // return false;
                }
              }}
              background="#2F8CAE"
              color="#fff"
              name="登録"
              border="none"
              bdr="6px"
              fontSize={16}
              lineHeight="16px"
              fontWeight={700}
              width="128px"
              disabled={isCreatingDoctor}
              padding="8px 48px 7px"
            />
          </div>
          <WrapperCreatePhysician>
            <div className="left-block">
              <GroupInputWrapper>
                <div className="item">
                  <Controller
                    control={control}
                    name="last_name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        label="姓"
                        fsLabel={16}
                        height={31}
                        onChange={onChange}
                        error={error?.message}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="first_name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        label="名"
                        fsLabel={16}
                        height={31}
                        onChange={onChange}
                        error={error?.message}
                      />
                    )}
                  />
                </div>
                <div className="item">
                  <Controller
                    control={control}
                    name="kana_last_name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        label="姓（ふりがな）"
                        fsLabel={16}
                        height={31}
                        onChange={onChange}
                        error={error?.message}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="kana_first_name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        label="名（ふりがな）"
                        fsLabel={16}
                        height={31}
                        onChange={onChange}
                        error={error?.message}
                      />
                    )}
                  />
                </div>
              </GroupInputWrapper>
              <GroupInputWrapper>
                <Controller
                  control={control}
                  name="email"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      label="メールアドレス"
                      fsLabel={16}
                      height={31}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="password"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      label="初期パスワード"
                      fsLabel={16}
                      height={31}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </GroupInputWrapper>
            </div>
            <div className="right-block">
              <div className="label">所属病院選択</div>
              <div className="select-block">
                <Select
                  value={selectedHospital}
                  width="156px"
                  options={createOptions}
                  placeholder="選択してください"
                  height="31px"
                  onChange={(e: any) => {
                    setSelectedHospital(e);
                  }}
                  error={selectHospitalError ? selectHospitalError : ""}
                />
                <Button
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (
                      selectedHospitals.length &&
                      (selectedHospital || selectedHospital === 0) &&
                      !selectedHospitals.includes(selectedHospital)
                    ) {
                      setSelectedHospitals([
                        ...selectedHospitals,
                        selectedHospital,
                      ]);
                    }
                    if (
                      !selectedHospitals.length &&
                      (selectedHospital || selectedHospital === 0)
                    ) {
                      setSelectedHospitals([selectedHospital]);
                      setSelectHospitalError("");
                    }
                    setSelectedHospital(undefined);
                  }}
                  background="#2AC769"
                  color="#fff"
                  name="追加"
                  border="none"
                  bdr="4px"
                  fontSize={14}
                  lineHeight="20px"
                  fontWeight={400}
                  disabled={isCreatingDoctor}
                  padding="6px 8px 5px"
                />
              </div>
              <div className="area">
                <div className="overflow-wrapper">
                  {selectedHospitals.length > 0 &&
                    selectedHospitals.map((hospital: number) => (
                      <div key={hospital} className="badge">
                        <span>{returnName(hospital)}</span>
                        <CloseIcon
                          width={12}
                          height={12}
                          fill="#3B3B3B"
                          onClick={() => {
                            const tempSelectedHospitals = [
                              ...selectedHospitals,
                            ];
                            setSelectedHospitals(
                              tempSelectedHospitals.filter(
                                (item: number) => item !== hospital
                              )
                            );
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </WrapperCreatePhysician>
        </form>
      </Box>
      <Box padding="16px">
        <Title>登録医師一覧</Title>
        <TableStyled
          columns={columns}
          dataSource={doctorList?.result || []}
          scroll={{ x: "max-content" }}
          loading={isLoadingDoctor}
        />
      </Box>

      {isConfirmModal && (
        <ModalStyled visible={isConfirmModal} type="confirm" footer={null}>
          <WrapperContentModal>
            <div>医師アカウントを削除します。</div>
            <div>よろしいですか？</div>
            <WrapperBtnFooter>
              <Button
                onClick={() => setIsConfirmModal(false)}
                background="#E0E0E0"
                color="#fff"
                name="キャンセル"
                border="none"
                bdr="6px"
                fontSize={16}
                fontWeight={700}
              />

              <Button
                onClick={handleDeleteDoctor}
                background="#FB4E4E"
                color="#fff"
                name="はい"
                border="none"
                bdr="6px"
                fontSize={16}
                fontWeight={700}
              />
            </WrapperBtnFooter>
          </WrapperContentModal>
        </ModalStyled>
      )}
    </WrapperPhysicianManagement>
  );
};

export default PhysicianManagement;
